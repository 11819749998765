import React, { useState } from "react";

const Projects: React.FC = () => {
  const [initialCapital, setInitialCapital] = useState("");
  const [dailyIncrease, setDailyIncrease] = useState("");
  const [finalTarget, setFinalTarget] = useState("");
  const [result, setResult] = useState("");
  const [currentDayProfit, setCurrentDayProfit] = useState("");

  const calculate = () => {
    if (initialCapital && dailyIncrease && finalTarget) {
      let days = 0;
      let capital = parseFloat(initialCapital);
      const target = parseFloat(finalTarget);
      const increaseRate = 1 + parseFloat(dailyIncrease) / 100;

      while (capital < target) {
        capital *= increaseRate;
        days++;
      }

      setResult(`תגיע ליעד תוך ${days} ימי מסחר.`);
    } else {
      setResult("אנא הזן את כל הנתונים הדרושים לחישוב.");
    }
  };

  const calculateDailyProfit = () => {
    if (initialCapital && dailyIncrease) {
      const capital = parseFloat(initialCapital);
      const dailyRate = parseFloat(dailyIncrease) / 100;
      const profitToday = (capital * dailyRate).toFixed(2);
      setCurrentDayProfit(
        `אתה צריך להרוויח $${profitToday} היום כדי לעמוד ביעד.`
      );
    } else {
      setCurrentDayProfit("אנא הזן את גודל התיק ואחוז הגידול היומי.");
    }
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        padding: "20px",
        maxWidth: "500px",
        margin: "0 auto",
      }}
    >
      <h1>חישוב ימי מסחר ורווח יומי</h1>
      <label>
        גודל תיק התחלתי ($):
        <input
          type="number"
          value={initialCapital}
          onChange={(e) => setInitialCapital(e.target.value)}
          style={{ width: "100%", marginBottom: "10px", padding: "5px" }}
        />
      </label>
      <label>
        אחוז גידול יומי (%):
        <input
          type="number"
          value={dailyIncrease}
          onChange={(e) => setDailyIncrease(e.target.value)}
          style={{ width: "100%", marginBottom: "10px", padding: "5px" }}
        />
      </label>
      <label>
        יעד סופי ($):
        <input
          type="number"
          value={finalTarget}
          onChange={(e) => setFinalTarget(e.target.value)}
          style={{ width: "100%", marginBottom: "10px", padding: "5px" }}
        />
      </label>
      <button
        onClick={calculate}
        style={{
          backgroundColor: "#4CAF50",
          color: "white",
          border: "none",
          padding: "10px",
          cursor: "pointer",
          width: "100%",
          marginBottom: "10px",
        }}
      >
        חישוב ימי מסחר
      </button>
      {result && (
        <div
          style={{
            marginTop: "20px",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            backgroundColor: "#f9f9f9",
          }}
        >
          <strong>תוצאה:</strong> {result}
        </div>
      )}
      <button
        onClick={calculateDailyProfit}
        style={{
          backgroundColor: "#2196F3",
          color: "white",
          border: "none",
          padding: "10px",
          cursor: "pointer",
          width: "100%",
          marginTop: "10px",
        }}
      >
        חישוב רווח יומי
      </button>
      {currentDayProfit && (
        <div
          style={{
            marginTop: "20px",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            backgroundColor: "#f9f9f9",
          }}
        >
          <strong>רווח יומי:</strong> {currentDayProfit}
        </div>
      )}
    </div>
  );
};

export default Projects;
